<template>
  <v-container>
    <v-card
      tile
      max-width="344"
      class="mt-5 mx-auto"
    >
      <v-card-title>Вход</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="form.email"
            label="Email"
            outlined
            dense
            type="email"
            :rules="rules.email"
            :error-messages="errors.email"
            required
            validate-on-blur
            autocomplete="username"
            @change="errors = {}"
            @click="errors = {}"
            @keydown.enter="$refs.passwordField.focus()"
          />
          <v-text-field
            ref="passwordField"
            v-model="form.password"
            label="Пароль"
            outlined
            dense
            type="password"
            :rules="rules.password"
            :error-messages="errors.password"
            required
            validate-on-blur
            autocomplete="current-password"
            @change="errors = {}"
            @click="errors = {}"
            @keydown.enter="submit"
          />
          <v-checkbox
            v-model="form.remember"
            class="mt-0 mb-2"
            label="Запомнить меня"
          />
          <div
            class="d-flex justify-space-between"
          >
            <v-btn
              ref="buttonSubmit"
              :loading="isSubmitting"
              :disabled="isSubmitting"
              color="primary"
              @click="submit"
            >
              Войти
            </v-btn>
            <v-btn
              :disabled="isSubmitting"
              text
              @click="$router.push({ name: 'register' })"
            >
              Регистрация
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  // import axios from 'axios'
  import { mapActions } from 'vuex'

  export default {
    name: 'AuthLogin',

    data () {
      return {
        isSubmitting: false,
        valid: true,
        form: {
          email: '',
          password: '',
          remember: false
        },
        rules: {
          email: [
            v => !!v || 'Поле email обязательно для заполнения.',
            v => /.+@.+\..+/.test(v) || 'Поле должно содержать корректный email-адрес.',
          ],
          password: [
            v => !!v || 'Пароль не может быть пустым.',
          ]
        },
        errors: {},
      }
    },

    methods: {
      ...mapActions({
        loginAction: 'auth/login'
      }),

      async submit () {
        if (this.$refs.form.validate()) {
          this.isSubmitting = true
          await this.loginAction(this.form)
            .then(() => {
              this.$router.replace({ name: 'home' });
            })
            .catch((error) => {
              this.errors = {};
              if (error?.response?.data?.errors?.email) {
                this.errors.email = error?.response?.data?.errors?.email[0];
              }
              if (error?.response?.data?.errors?.password) {
                this.errors.password = error?.response?.data?.errors?.password[0];
              }
            })
            .finally(() => {
              this.isSubmitting = false;
            })
        }
      }
    }
  }
</script>

<template>
  <v-container>
    <v-card
      tile
      max-width="344"
      class="mt-5 mx-auto"
    >
      <v-card-title>Регистрация</v-card-title>
      <v-card-text v-if="!success">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="form.name"
            label="Имя и фамилия"
            outlined
            dense
            type="text"
            :rules="rules.name"
            :error-messages="errors.name"
            validate-on-blur
            autocomplete="name"
            required
            @change="errors = {}"
            @click="errors = {}"
            @keydown.enter="$refs.emailField.focus()"
          />
          <v-text-field
            ref="emailField"
            v-model="form.email"
            label="E-mail"
            outlined
            dense
            type="email"
            :rules="rules.email"
            :error-messages="errors.email"
            validate-on-blur
            autocomplete="username"
            required
            @change="errors = {}"
            @click="errors = {}"
            @keydown.enter="$refs.passwordField.focus()"
          />
          <v-text-field
            ref="passwordField"
            v-model="form.password"
            type="password"
            label="Придумайте пароль"
            outlined
            dense
            :rules="rules.password"
            :error-messages="errors.password"
            validate-on-blur
            autocomplete="new-password"
            required
            @change="errors = {}"
            @click="errors = {}"
            @keydown.enter="submit"
          />
          <div
            class="d-flex justify-space-between"
          >
            <v-btn
              ref="buttonSumbit"
              :loading="isSubmitting"
              :disabled="isSubmitting"
              color="primary"
              @click="submit"
            >
              Регистрация
            </v-btn>
            <v-btn
              :disabled="isSubmitting"
              text
              @click="$router.push({ name: 'login' })"
            >
              Вход
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'AuthRegister',

    data() {
      return {
        isSubmitting: false,
        valid: true,
        form: {
          name: '',
          email: '',
          password: '',
        },
        rules: {
          name: [
            v => !!v || 'Поле обязательно для заполнения.',
          ],
          email: [
            v => !!v || 'Поле email обязательно для заполнения.',
            v => /.+@.+\..+/.test(v) || 'Поле должно содержать корректный email-адрес.',
          ],
          password: [
            v => !!v || 'Пароль не может быть пустым.',
            v => (v || '').length >= 4 || 'Пароль должен содержать минимум 4 символа.'
          ]
        },
        errors: {},
        success: false
      }
    },

    methods: {
      ...mapActions({
        registerAction: 'auth/register'
      }),

      async submit () {
        if (this.$refs.form.validate()) {
          this.isSubmitting = true;
          await this.registerAction(this.form)
            .then(() => {
              this.$router.replace({ name: 'home' })
            })
            .catch((error) => {
              this.errors = {};
              if (error?.response?.data?.errors?.name) {
                this.errors.name = error?.response?.data?.errors?.name[0];
              }
              if (error?.response?.data?.errors?.email) {
                this.errors.email = error?.response?.data?.errors?.email[0];
              }
              if (error?.response?.data?.errors?.password) {
                this.errors.password = error?.response?.data?.errors?.password[0];
              }
            })
            .finally(() => {
              this.isSubmitting = false;
            })
        }
      }
    }
  }
</script>
